<template>
  <Html class="scroll-smooth">
  <Body class="font-normal antialiased text-gray-700" >
  <NuxtLoadingIndicator />
  <NuxtLayout :layout="layout">
    <NuxtPage />
  </NuxtLayout>
  </Body>
  </Html>
</template>

<script setup lang="ts">
  const { data } = await useAsyncData(() => queryContent('layout/').find())
  const layout: Record<string, any> = {}

  data.value?.forEach(item => {
    const path = item._file
    const fileName = path?.substring(path.lastIndexOf('/') + 1).split('.').slice(0, -1).join('.')
    if (fileName) {
      layout[fileName] = item
    }
  })

  const { app } = layout

  useHead({
    title: app.title,
    htmlAttrs: {
      lang: 'ru'
    },
    script: [
      {
        src: 'https://identity.netlify.com/v1/netlify-identity-widget.js',
        type: 'text/javascript',
        async: true,
      },
    ],
  })
</script>
