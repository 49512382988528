import { default as _91_46_46_46slug_93ol4gZFNHYgMeta } from "/home/runner/work/frontend/frontend/src/pages/[...slug].vue?macro=true";
import { default as aboutjhR7jWJH8RMeta } from "/home/runner/work/frontend/frontend/src/pages/about.vue?macro=true";
import { default as indexo2mBF5S52tMeta } from "/home/runner/work/frontend/frontend/src/pages/index.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/home/runner/work/frontend/frontend/src/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "about",
    path: "/about",
    component: () => import("/home/runner/work/frontend/frontend/src/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/frontend/frontend/src/pages/index.vue").then(m => m.default || m)
  }
]