export default defineAppConfig({
  ui: {
    primary: 'pink',
    gray: 'neutral',
    button: {
      base: 'transition',
      rounded: 'rounded-full',
      font: 'font-semibold',
      size: {
        lg: 'text-sm',
        xl: 'text-base'
      },
      padding: {
        lg: 'px-8 py-3',
        xl: 'px-12 py-4'
      },
      variant: {
        solid: 'text-white bg-{color}-500 hover:bg-primary-300',
        solidInverse: 'text-gray-300 bg-gray-900 hover:text-gray-300 hover:bg-gray-700',
        outline: 'ring-1 ring-inset ring-black text-{color}-500 hover:bg-{color}-500 hover:text-white hover:ring-transparent',
        link: 'text-{color}-500 hover:text-{color}-600 underline-offset-4 hover:underline',
        soft: 'bg-primary-300 hover:bg-{color}-500'
      },
      default: {
        size: 'lg',
      },
    },
  }
})
